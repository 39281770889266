import React, { ReactNode } from "react";
import { SelectProps, Select } from "@mui/material";
import { useTheme } from "@patron/utils/theme";

type CustomSelectComponentProps = SelectProps & {
  controldisabled?: boolean;
  useFullWidth?: boolean;
  children: ReactNode;
  size?: string;
};

export const CustomSelectComponent = ({
  size = "small",
  useFullWidth = true,
  controldisabled,
  children,
  ...others
}: CustomSelectComponentProps) => {
  const theme = useTheme();

  return (
    <Select
      id="CustomSelect"
      disabled={controldisabled}
      variant="filled"
      fullWidth={useFullWidth ? true : false}
      size={size}
      error={others.error}
      {...others}
    >
      {children}
    </Select>
  );
};

export default CustomSelectComponent;
