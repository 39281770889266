import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material/styles";
import { useTheme } from "@patron/utils/theme";
import { IconType } from "react-icons";
import { Icon, IconButton, Tooltip } from "@mui/material";

export type CustomTextFieldProps = TextFieldProps & {
  controldisabled?: boolean;
  useFullWidth?: boolean;
  size?: string;
  sx?: SxProps<Theme>;
  iconButton?: {
    icon: IconType;
    onClick: () => void;
    tooltip?: string;
    color?:
      | "disabled"
      | "error"
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "inherit"
      | "action";
  };
};

export const CustomTextField = ({
  size = "small",
  useFullWidth = true,
  controldisabled,
  variant = "filled",
  value,
  iconButton,
  sx,
  ...props
}: CustomTextFieldProps) => {
  const theme = useTheme();

  const iconBtn = iconButton ? (
    iconButton.tooltip ? (
      <Tooltip title={iconButton.tooltip}>
        <IconButton onClick={iconButton.onClick}>
          <Icon
            component={iconButton.icon}
            color={iconButton.color || "inherit"}
          />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton onClick={iconButton.onClick}>
        <Icon
          component={iconButton.icon}
          color={iconButton.color || "inherit"}
        />
      </IconButton>
    )
  ) : null;

  return (
    <TextField
      inputProps={{ readOnly: controldisabled }}
      fullWidth={useFullWidth ? true : false}
      disabled={controldisabled}
      value={value || ""}
      size={size}
      variant={variant}
      error={props.error}
      required={props.required}
      sx={[
        {
          "&.MuiTextField-root .Mui-disabled ": {
            // backgroundColor: theme.appColors.surfaceContainerLow,
            color: theme.appPalette.neutral[30],
            opacity: 0.9,
            pointerEvents: "unset",
            "&&:hover": {
              cursor: "default",
            },
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: theme.appPalette.neutral[30],
            WebkitTextFillColor: theme.appPalette.neutral[30],
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
      InputProps={{
        ...props.InputProps,
        startAdornment: iconBtn ? iconBtn : props.InputProps?.startAdornment,
      }}
    />
  );
};

export default CustomTextField;
